var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"after-close":_vm.afterModalClose,"title":_vm.modalTitle,"ok-button-props":{
    props: {
      disabled: !_vm.localUserId.length
    }
  }},on:{"ok":_vm.promoteAdmins},model:{value:(_vm.isAddAdminModalOpen),callback:function ($$v) {_vm.isAddAdminModalOpen=$$v},expression:"isAddAdminModalOpen"}},[_c('a-spin',{attrs:{"spinning":_vm.permissionsLoading}},[_c('a-spin',{attrs:{"spinning":_vm.usersInfoService.isLoading}},[_c('chips-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.userSetter,
          'args': {
            'model': this,
            'key': 'localUserId',
            'validation': 'required',
            'formatter': _vm.format
          }
        }}})],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v(" "+_vm._s(_vm.$t('admin_permissions'))+" ")]),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanChangeInfo,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanDeleteMessages,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanRestrictMembers,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanInviteUsers,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanPinMessages,
        }
      }}}),_c('div',{staticClass:"flex items-center"},[_c('config-field',{staticClass:"hover:bg-gray-200 hover:opacity-75 w-full cursor-pointer select-none",attrs:{"title":_vm.$t('field_frontend_manage_stories_title', [_vm.activeStoriesPermissionsLen, _vm.STORIES_PERMISSIONS_COUNT])},nativeOn:{"click":function($event){return _vm.toggleStoriesPermissionsBlock.apply(null, arguments)}},scopedSlots:_vm._u([{key:"titleButton",fn:function(){return [_c('a-button',{attrs:{"type":"link","size":"small","icon":!_vm.storiesPermissionsBlock ? 'arrow-down' : 'arrow-up'}})]},proxy:true}])}),_c('switch-input',{staticClass:"ml-auto mt-0 mb-0",attrs:{"setup":{
          'func': _vm.storiesAllSetter,
          'args': {
            'model': this,
            'key': 'storiesPermissionsAll',
            'titleVisible': false
          }
        }}})],1),(_vm.storiesPermissionsBlock)?_c('nested-content',[_c('checkbox-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.storiesSetter,
          'args': {
            'model': _vm.permissions,
            'key': _vm.AdminPermissionsEnum.CanPostStories,
          }
        }}}),_c('checkbox-input',{attrs:{"setup":{
          'func': _vm.storiesSetter,
          'args': {
            'model': _vm.permissions,
            'key': _vm.AdminPermissionsEnum.CanEditStories,
          }
        }}}),_c('checkbox-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.storiesSetter,
          'args': {
            'model': _vm.permissions,
            'key': _vm.AdminPermissionsEnum.CanDeleteStories,
          }
        }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanManageVideoChats,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanPromoteMembers,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'anon',
        }
      }}}),(_vm.$store.getters.isChatForum)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanManageTopics,
        }
      }}}):_vm._e(),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.permissions,
          'key': _vm.AdminPermissionsEnum.CanManageChat,
        }
      }}})],1),(_vm.showAddViaBotAlert)?_c('a-alert',{staticClass:"mt-2",attrs:{"message":_vm.$t('promote_admin_via_bot_notification'),"type":"error","banner":"","show-icon":"","closable":""},on:{"close":function($event){_vm.showAddViaBotAlert=false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }