





























































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '@/mixins/input-setups'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums'
import { isDeprecatedChatConfig } from "@/includes/types/Chat/predicates";
import { NewChatConfig } from "@/includes/types/Chat/types";
import ReceivedAdministrators from "@/components/UsersRights/ReceivedAdministrators/ReceivedAdministrators.vue";
import SiteAdmins from "@/components/UsersRights/SiteAdmins/SiteAdmins.vue";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from "lodash";

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  components: {
    SiteAdmins,
    ReceivedAdministrators,
    AddAdmin,
    ConfigField,
    CenteredColumnLayout,
    PageTitle,
    SwitchInput
  },
  data: () => ({
    AddAdminType
  }),
})
export default class UsersRights extends Mixins(InputSetups) {
  saveConfig() {
    this.$store.dispatch('saveChatConfigRequest')
  }

  get isChatConfigDeprecated() {
    return isDeprecatedChatConfig(this.$store.state.chatState.chat)
  }

  get gods_admins() {
    if (!this.isChatConfigDeprecated) {
      return this.$store.state.chatState.chat!.common_config.gods_admins
    } else {
      return this.stateChatConfigPath.gods_admins
    }
  }

  set gods_admins(value: boolean) {
    if (!this.isChatConfigDeprecated) {
      this.$store.dispatch('saveChatConfig', { config: { gods_admins: value } })
    } else {
      this.stateChatConfigPath.gods_admins = value
    }
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
